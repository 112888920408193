<template>
  <section>
    <div class="row ml-0 mr-0 table-utilities">
      <div class="row ml-2 w-100">
        <div class="col-md-8 search-section">
          <div class="input-group">
            <div class="input-group-prepend bg-white">
              <span class="input-group-text" id="basic-addon1">
                <div class="font-12">
                  <i class="ri-search-line ri-lg ri-sub"></i>
                </div>
              </span>
            </div>
            <input
              type="text"
              class="form-control search-input"
              :placeholder="$t('modulsam.inv.search')"
              @input="setPage"
              v-model="filterInput"
            />
          </div>
        </div>
        <div class="col-md-2 pl-0">
          <select class="custom-select" v-model="filters.produkId">
            <option v-for="prod in dataProduk" :key="prod.id" :value="prod.id">
              {{ prod.brand }}
            </option>
          </select>
        </div>
        <div class="col-md-2 pl-0">
          <select class="custom-select" v-model="filters.matproId">
            <option v-for="mp in dataMatpro" :key="mp.id" :value="mp.id">
              {{ `${mp.jenisMatpros.matpro} - ${mp.produks.brand}` }}
            </option>
          </select>
        </div>
        <!-- <div class="col-md-2 pl-0">
          <div
            class="btn table-button ssp-14-700 export-button-disabled h-100 d-flex align-items-center"
            @click="handleExport"
          >
            <div class="font-12">
              <i class="ri-download-line ri-lg ri-mid mr-1"></i>
            </div>
            {{ $t("global.export") }}
          </div>
        </div> -->
      </div>
    </div>
    <div class="table-borderless">
      <b-table-simple hover responsive style="overflow: hidden;">
        <b-thead class="bv-head">
          <b-tr>
            <b-th>#</b-th>
            <b-th v-for="hd in $t('modulsam.inv.headers')" :key="hd.id">
              {{ hd }}
            </b-th>
            <!-- <b-th>Action</b-th> -->
          </b-tr>
        </b-thead>
        <b-tbody v-if="inventories.length !== 0">
          <b-tr
            class="ssp-16-400"
            v-for="(inv, index) in inventories"
            :key="inv.id"
          >
            <b-td>
              {{ ++index + (currPage - 1) * perPage }}
            </b-td>
            <b-td>
              {{
                `${inv.matpros.jenisMatpros.matpro} - ${inv.matpros.produks.brand}`
              }}
            </b-td>
            <b-td>{{ inv.jumlah }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else class="text-center text-italic">
          <b-tr>
            <b-td colspan="7">{{ $t("global.empty") }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="row align-items-center table-footer">
        <div class="col-sm-6 ssp-14-700">
          <!-- 1 - 6 of {{ this.totalData }} -->
          {{ $t("global.total_data") }} {{ this.totalData }}
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-7 align-self-center page-text">
              {{ $t("global.page_on") }}
            </div>
            <div class="col float-right">
              <div class="row">
                <select class="form-control pagination-pg" v-model="currPage">
                  <option
                    v-for="index in totalPages"
                    :key="index.id"
                    :value="index"
                  >
                    {{ index++ }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col page-arrow ssp-14-700 ml-2">
              <div class="row float-right">
                <div
                  @click="redcPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div class="font-12" :class="{ orange: currPage > 1 }">
                    <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                  </div>
                </div>
                <div
                  @click="addPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div
                    class="font-12"
                    :class="{ orange: currPage < totalPages }"
                  >
                    <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import { inventoryType } from "@/graphql/enum.type";
import { Q_LIST_PRODUK, listInventories, listMatpros } from "@/graphql/queries";
import * as XLSX from "xlsx";

export default {
  components: {},
  data() {
    return {
      inventories: [],
      dataProduk: [],
      dataMatpro: [],
      filters: {},

      currPage: 1,
      perPage: 10,
      totalData: null,
      totalPages: null,
      filterInput: "",
      inventoryTypes: [inventoryType.MATPRO, inventoryType.SAMPLE]
    };
  },
  apollo: {
    listInventory: {
      query: () => listInventories,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          userId: JwtService.getID(),
          produkId: this.filters.produkId,
          matproId: this.filters.matproId,
          tipe: this.inventoryTypes[0],
          isGetFromMobile: false,
          orderBy: "CREATED_AT",
          sortBy: "DESC"
        };
      },
      result({ data }) {
        this.inventories = data.listInventory.inventorys;
        this.totalPages = data.listInventory.totalPage;
        this.totalData = data.listInventory.total;
      }
    },
    listProduk: {
      query: () => Q_LIST_PRODUK,
      variables() {
        return {
          page: 1,
          limit: 9999,
          keyword: ""
        };
      },
      result({ data }) {
        this.dataProduk = data.listProduk.produks;
      }
    },
    listMaterialPromosi: {
      query: () => listMatpros,
      variables() {
        return {
          page: 1,
          limit: 999,
          keyword: "",
          jenisMatproId: "",
          produkId: this.filters.produkId,
          orderBy: "UPDATED_AT",
          sortBy: "DESC"
        };
      },
      result({ data }) {
        this.dataMatpro = data.listMaterialPromosi.matpros;
      }
    }
  },
  methods: {
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    async handleExport() {
      this.isLoading = true;
      this.perPage = this.totalData;
      this.currPage = 1;
      setTimeout(() => {
        const filter = [];
        for (let i = 0; i < this.dataPetani.length; i++) {
          let negara, wilayah, provinsi, kab, kec, kel, uCrop, oCrop;
          if (this.dataPetani[i].countrys != null) {
            negara = this.dataPetani[i].countrys.country;
          }
          if (this.dataPetani[i].regions != null) {
            wilayah = this.dataPetani[i].regions.region;
          }
          if (this.dataPetani[i].prov != null) {
            provinsi = this.dataPetani[i].prov.province;
          }
          if (this.dataPetani[i].kab != null) {
            kab = this.dataPetani[i].kab.kabupaten;
          }
          if (this.dataPetani[i].kec != null) {
            kec = this.dataPetani[i].kec.kecamatan;
          }
          if (this.dataPetani[i].kel != null) {
            kel = this.dataPetani[i].kel.kelurahan;
          }
          if (this.dataPetani[i].ucrops != null) {
            uCrop = this.dataPetani[i].ucrops.crop;
          }
          if (this.dataPetani[i].ocrops != null) {
            oCrop = this.dataPetani[i].ocrops.crop;
          }
          filter.push({
            name: this.dataPetani[i].name,
            sp: this.dataPetani[i].sp.name,
            tss: this.dataPetani[i].tss.name,
            country: negara,
            region: wilayah,
            province: provinsi,
            kabupaten: kab,
            kecamatan: kec,
            kelurahan: kel,
            alamat: this.dataPetani[i].alamat,
            hp: this.dataPetani[i].hp,
            luasHA: this.dataPetani[i].luas,
            tutama: uCrop,
            tlain: oCrop
          });
        }
        const data = XLSX.utils.json_to_sheet(filter);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "Rencana Bulanan");
        XLSX.writeFile(wb, "monthlyplan.xlsx");
        this.perPage = 10;
      }, 2000);
    }
  },
  mounted() {}
};
</script>

<style>
.custom-select {
  background-color: #fff;
  border-radius: 8px;
}
</style>
