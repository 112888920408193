<template>
  <section>
    <div class="row ml-0 mr-0 table-utilities">
      <div class="row ml-2 w-100">
        <div class="col-md-8 search-section">
          <div class="input-group">
            <div class="input-group-prepend bg-white">
              <span class="input-group-text" id="basic-addon1">
                <div class="font-12">
                  <i class="ri-search-line ri-lg ri-sub"></i>
                </div>
              </span>
            </div>
            <input
              type="text"
              class="form-control search-input"
              :placeholder="$t('proposalmp.search')"
              @input="setPage"
              v-model="filterInput"
            />
          </div>
        </div>
        <div class="col-md-2 pl-0">
          <select class="custom-select" v-model="filters.periode">
            <option v-for="per in periods" :key="per.id" :value="per.id">
              {{ dateFr(per.startDate, "MM/YYYY") }} -
              {{ dateFr(per.endDate, "MM/YYYY") }}
            </option>
          </select>
        </div>
        <div class="col-md-2 pl-0">
          <!-- {{ filters.status }} -->
          <select class="custom-select" v-model="filters.status">
            <option v-for="st in statusProposals" :key="st.id" :value="st">
              {{ st }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- {{ visibility }} -->
    <div class="table-borderless">
      <b-table-simple hover responsive style="overflow: hidden;">
        <b-thead class="bv-head">
          <b-tr>
            <b-th>#</b-th>
            <b-th v-for="hd in $t('modulsam.req.headers')" :key="hd.id">
              {{ hd }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="requests.length !== 0">
          <b-tr
            class="ssp-16-400"
            v-for="(req, index) in requests"
            :key="req.id"
          >
            <b-td>
              {{ ++index + (currPage - 1) * perPage }}
            </b-td>
            <b-td>{{ dateFr(req.createdAt, "DD/MM/YYYY") }}</b-td>
            <b-td>
              {{
                `${dateFr(req.periods.startDate, "MMMM YYYY")} - ${dateFr(
                  req.periods.endDate,
                  "MMMM YYYY"
                )}`
              }}
            </b-td>
            <b-td>
              <span class="badge" :class="handleBadge(req.status)">
                {{ req.status }}
              </span>
            </b-td>
            <b-td>
              <i
                class="ri-eye-fill ri-lg ri-mid action-btn"
                @click="onDetail(req.id)"
              ></i>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else class="text-center text-italic">
          <b-tr>
            <b-td colspan="7">{{ $t("global.empty") }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="row align-items-center table-footer">
        <div class="col-sm-6 ssp-14-700">
          <!-- 1 - 6 of {{ this.totalData }} -->
          {{ $t("global.total_data") }} {{ this.totalData }}
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-7 align-self-center page-text">
              {{ $t("global.page_on") }}
            </div>
            <div class="col float-right">
              <div class="row">
                <select class="form-control pagination-pg" v-model="currPage">
                  <option
                    v-for="index in totalPages"
                    :key="index.id"
                    :value="index"
                  >
                    {{ index++ }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col page-arrow ssp-14-700 ml-2">
              <div class="row float-right">
                <div
                  @click="redcPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div class="font-12" :class="{ orange: currPage > 1 }">
                    <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                  </div>
                </div>
                <div
                  @click="addPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div
                    class="font-12"
                    :class="{ orange: currPage < totalPages }"
                  >
                    <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="visibility"
      id="modal-laporan"
      :no-close-on-backdrop="true"
      :size="modalSize"
      hide-footer
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="closeModal()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("modulsam.req.label.pd") }}
            </label>
            <multiselect
              :placeholder="$t('modulsam.req.placeholder.pd')"
              v-model="forms.periode"
              :options="periods.map(mp => mp.id)"
              :custom-label="
                opt =>
                  `
                    ${datePr(periods.find(x => x.id === opt).startDate)} - 
                    ${datePr(periods.find(x => x.id === opt).endDate)}
                  `
              "
              :class="{
                'invalid-form rounded-input': $v.forms.periode.$error
              }"
              :allow-empty="false"
              id="request-mpiv"
            />
          </div>
          <hr />
          <section v-for="(form, index) in details" :key="form.id">
            <b-row>
              <b-col>
                <div class="form-group">
                  <label class="typo__label ssp-16 text-black">
                    {{ $t("modulsam.bd.label.mp") }}
                  </label>
                  <span
                    v-if="index > 0"
                    class="float-right mr-1 rm-mp pointer"
                    @click="removeMatpro"
                  >
                    Delete
                  </span>
                  <multiselect
                    :placeholder="$t('modulsam.bd.placeholder.mp')"
                    v-model="form.matproId"
                    :options="matpros.map(mp => mp.id)"
                    :custom-label="
                      opt =>
                        `${
                          matpros.find(x => x.id === opt).jenisMatpros.matpro
                        } - ${matpros.find(x => x.id === opt).produks.brand}`
                    "
                    :allow-empty="false"
                    id="request-mpiv"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="form-group">
                  <label class="typo__label ssp-16 text-black">
                    {{ $t("modulmp.label.purpose") }}
                  </label>
                  <multiselect
                    :placeholder="$t('modulmp.placeholder.purpose')"
                    v-model="form.tujuanPromosi"
                    :options="tujuanPromosi"
                    :custom-label="opt => `${convertTujuanPromosi(opt)}`"
                    selectLabel=""
                    id="request-mptj"
                  />
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <label class="typo__label ssp-16 text-black">
                    {{ $t("modulmp.label.amount") }}
                  </label>
                  <input
                    id="breakdown-jumlah"
                    class="form-control bd-jml"
                    :placeholder="$t('modulmp.placeholder.amount')"
                    type="number"
                    v-model="form.kebutuhanSample"
                  />
                </div>
              </b-col>
            </b-row>
            <hr class="mt-2" />
          </section>
          <b-row>
            <b-col col md="5" class="pr-2">
              <a
                class="btn btn-success ssp-14 w-100 my-3 py-2"
                @click="addMatpro"
              >
                {{ $t("modulmp.add") }}
              </a>
            </b-col>
            <b-col col md="7" class="pl-2">
              <button
                type="submit"
                class="btn btn-primary ssp-14 w-100 my-3 py-2"
              >
                {{ $t("modulsam.req.submit") }}
              </button>
            </b-col>
          </b-row>
        </form>
      </template>
    </b-modal>
    <b-modal id="detail-req-mp" size="lg" centered hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">Detail</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <section class="req-data" v-if="Object.keys(detailRequest).length > 1">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <th>{{ $t("proposalmp.tss") }}</th>
                <td>:</td>
                <td>
                  {{ detailRequest.users.name }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("proposalmp.period") }}</th>
                <td>:</td>
                <td>
                  {{
                    `${dateFr(
                      detailRequest.periods.startDate,
                      "MMMM YYYY"
                    )} - ${dateFr(detailRequest.periods.endDate, "MMMM YYYY")}`
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("proposalmp.sp") }}</th>
                <td>:</td>
                <td>
                  {{ detailRequest.jumlahSP }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section v-if="Object.keys(detailRequest).length > 1">
          <table class="table table-hover tbl-proposals">
            <thead>
              <tr>
                <th>#</th>
                <th v-for="hd in $t('proposalmp.pheaders')" :key="hd.id">
                  {{ hd }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in detailRequest.proposals"
                :key="item.id"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.matpros.jenisMatpros.matpro }}</td>
                <td>{{ item.matpros.produks.brand }}</td>
                <td>{{ item.tujuanPromosi }}</td>
                <td>{{ item.kebutuhanSample }}</td>
                <td>
                  {{ item.kebutuhanSampleApproved }}
                </td>
                <td>
                  {{ item.kebutuhanSampleApprovedGudang }}
                </td>
                <td>
                  <span class="badge" :class="handleBadge(item.status)">
                    {{ item.status }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section
          class="text-center"
          v-if="detailRequest.status === 'DELIVERED'"
        >
          <button
            class="btn btn-primary w-25 ssp-14 my-3 py-2 mr-2"
            @click="publishPropsReq(detailRequest.id, statusProposals[5])"
          >
            Terima Barang
          </button>
          <!-- <button
            class="btn btn-primary w-25 ssp-14 my-3 py-2"
            @click="publishPropsReq(details.id, statusProposals[1])"
          >
            {{ $t("proposalmp.approve") }}
          </button> -->
        </section>
      </template>
    </b-modal>
  </section>
</template>

<script>
// import JwtService from "@/core/services/jwt.service";
import Multiselect from "vue-multiselect";
import "../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { required } from "vuelidate/lib/validators";
import jwtService from "@/core/services/jwt.service";
import {
  RoleType,
  inventoryType,
  tujuanPromosi,
  statusProposalType
} from "@/graphql/enum.type";
import {
  detailProposalReq,
  listMatpros,
  listPeriods,
  listProposalReq
} from "@/graphql/queries";
import * as XLSX from "xlsx";
import { createProposalRequest } from "@/graphql/mutations";
import { Q_LIST_USER } from "@/graphql/tss1";
import { publishProposalRequest } from "@/graphql/mutations";

export default {
  data() {
    return {
      forms: {},
      details: [{}],
      detailRequest: {},

      periods: [],
      requests: [],
      matpros: [],
      filters: {
        periode: null,
        status: null
      },
      modalSize: "md",

      currPage: 1,
      perPage: 10,
      totalData: null,
      totalPages: null,
      filterInput: "",
      totalSP: 0,
      optionRole: [RoleType.SP],
      inventoryTypes: [inventoryType.MATPRO, inventoryType.SAMPLE],
      tujuanPromosi: [
        tujuanPromosi.DB,
        tujuanPromosi.EB,
        tujuanPromosi.KB,
        tujuanPromosi.PB,
        tujuanPromosi.SAFF,
        tujuanPromosi.SAFM,
        tujuanPromosi.SAOD
      ],
      statusProposals: [
        "ALL DATA",
        statusProposalType.CHECKED,
        statusProposalType.APPROVED,
        statusProposalType.PROCESS,
        statusProposalType.DELIVERED,
        statusProposalType.RECEIVED,
        statusProposalType.DECLINED
      ]
    };
  },
  components: {
    // BaseInput,
    Multiselect
  },
  apollo: {
    listRequestProposal: {
      query: () => listProposalReq,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          tipe: this.inventoryTypes[0],
          periodeId: this.filters.periode,
          status:
            this.filters.status === "ALL DATA" ? null : this.filters.status,
          userId: jwtService.getID(),
          orderBy: "CREATED_AT",
          sortBy: "DESC"
        };
      },
      debounce: 500,
      result({ data }) {
        this.requests = data.listRequestProposal.requestProposals;
        this.totalPages = data.listRequestProposal.totalPage;
        this.totalData = data.listRequestProposal.total;
      },
      error(e) {
        this.catchError(e);
      }
    },
    listPeriode: {
      query: () => listPeriods,
      variables() {
        return {
          page: 1,
          limit: 999,
          orderBy: "YEAR",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.periods = data.listPeriode.periods;
      },
      error(err) {
        this.catchError(err);
      }
    },
    listMaterialPromosi: {
      query: () => listMatpros,
      variables() {
        return {
          page: 1,
          limit: 999,
          keyword: "",
          jenisMatproId: "",
          produkId: "",
          orderBy: "UPDATED_AT",
          sortBy: "DESC"
        };
      },
      result({ data }) {
        this.matpros = data.listMaterialPromosi.matpros;
      }
    },
    listUser: {
      query: () => Q_LIST_USER,
      variables() {
        return {
          page: 1,
          limit: 9999999,
          keyword: "",
          isActive: null,
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          province_id: this.getProvinceID(),
          kabupaten_id: this.getKabupatenID(),
          kecamatan_id: [],
          kelurahan_id: [],
          // tss_id: [jwtService.getID()],
          tss_id: [],
          role: this.optionRole[0]
        };
      },
      result({ data }) {
        this.totalSP = data.listUser.total;
      }
    }
  },
  validations: {
    forms: {
      periode: { required }
    }
  },
  methods: {
    addMatpro() {
      this.details.push({});
    },
    removeMatpro(index) {
      this.details.splice(index, 1);
    },
    onDetail(id) {
      this.resetForm();
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .query({
              query: detailProposalReq,
              variables: {
                id: id
              },
              fetchPolicy: "no-cache"
            })
            .then(res => {
              this.detailRequest = res.data.detailRequestProposal;
              this.$bvModal.show("detail-req-mp");
              resolve();
            });
        } catch (e) {
          this.errorAlert(e);
          reject(e);
        }
      });
    },
    publishPropsReq(id, status) {
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .mutate({
              mutation: publishProposalRequest,
              variables: {
                id: id,
                status: status,
                userId: jwtService.getID()
              }
            })
            .then(() => {
              this.toastAlert(`Proposal ${status.toLowerCase()}`);
              this.$apollo.queries.listRequestProposal.refetch();
              this.$bvModal.hide("detail-req-mp");
              resolve();
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetForm() {
      this.forms = {};
      this.details = [{}];
    },
    closeModal() {
      this.$store.commit("changeModalRequest", false);
      this.resetForm();
      this.$v.$reset();
    },
    // async onSubmit(ev) {
    //   ev.preventDefault();
    //   this.forms.userId = jwtService.getID();
    //   this.forms.tipe = this.inventoryTypes[0];
    //   this.forms.jumlahSP = this.totalSP;
    //   this.forms.detail = this.details.map(a => {
    //     return {
    //       matproId: a.matproId,
    //       kebutuhanSample: parseFloat(a.kebutuhanSample),
    //       tujuanPromosi: a.tujuanPromosi
    //     };
    //   });
    //   console.log(this.forms);
    // },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.forms.userId = jwtService.getID();
        this.forms.tipe = this.inventoryTypes[0];
        this.forms.jumlahSP = this.totalSP;
        this.forms.detail = this.details.map(a => {
          return {
            matproId: a.matproId,
            kebutuhanSample: parseFloat(a.kebutuhanSample),
            tujuanPromosi: a.tujuanPromosi
          };
        });
        return new Promise((resolve, reject) => {
          const result = this.$apollo.mutate({
            mutation: createProposalRequest,
            variables: this.forms
          });
          if (resolve) {
            resolve(result);
          } else {
            reject(result);
          }
        })
          .then(response => {
            if (response) {
              this.$apollo.queries.listRequestProposal.refetch();
              this.toastAlert("Berhasil request proposal");
              this.closeModal();
            }
          })
          .catch(err => {
            this.errorAlert(err);
          });
      }
    },
    handleBadge(status) {
      switch (status) {
        case "CHECKED":
          return "badge-gray";
        case "APPROVED":
          return "badge-blue";
        case "PROCESS":
          return "badge-purple";
        case "DELIVERED":
          return "badge-purple-2";
        case "RECEIVED":
          return "badge-green";
        case "DECLINED":
          return "badge-red";
        default:
          break;
      }
    },
    async handleExport() {
      this.isLoading = true;
      this.perPage = this.totalData;
      this.currPage = 1;
      setTimeout(() => {
        const filter = [];
        // for (let i = 0; i < this.breakdowns.length; i++) {
        //   let negara, wilayah, provinsi, kab, kec, kel, uCrop, oCrop;
        //   if (this.breakdowns[i].countrys != null) {
        //     negara = this.breakdowns[i].countrys.country;
        //   }
        //   if (this.breakdowns[i].regions != null) {
        //     wilayah = this.breakdowns[i].regions.region;
        //   }
        //   if (this.breakdowns[i].prov != null) {
        //     provinsi = this.breakdowns[i].prov.province;
        //   }
        //   if (this.breakdowns[i].kab != null) {
        //     kab = this.breakdowns[i].kab.kabupaten;
        //   }
        //   if (this.breakdowns[i].kec != null) {
        //     kec = this.breakdowns[i].kec.kecamatan;
        //   }
        //   if (this.breakdowns[i].kel != null) {
        //     kel = this.breakdowns[i].kel.kelurahan;
        //   }
        //   if (this.breakdowns[i].ucrops != null) {
        //     uCrop = this.breakdowns[i].ucrops.crop;
        //   }
        //   if (this.breakdowns[i].ocrops != null) {
        //     oCrop = this.breakdowns[i].ocrops.crop;
        //   }
        //   filters.push({
        //     name: this.breakdowns[i].name,
        //     sp: this.breakdowns[i].sp.name,
        //     tss: this.breakdowns[i].tss.name,
        //     country: negara,
        //     region: wilayah,
        //     province: provinsi,
        //     kabupaten: kab,
        //     kecamatan: kec,
        //     kelurahan: kel,
        //     alamat: this.breakdowns[i].alamat,
        //     hp: this.breakdowns[i].hp,
        //     luasHA: this.breakdowns[i].luas,
        //     tutama: uCrop,
        //     tlain: oCrop
        //   });
        // }
        const data = XLSX.utils.json_to_sheet(filter);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "Rencana Bulanan");
        XLSX.writeFile(wb, "monthlyplan.xlsx");
        this.perPage = 10;
      }, 2000);
    }
  },
  computed: {
    visibility: {
      get() {
        return this.$store.getters.modalRequest;
      },
      set(value) {
        return value;
      }
    },
    modalTitle: {
      get() {
        return this.$store.getters.modalTitle;
      },
      set(value) {
        return value;
      }
    }
  },
  mounted() {
    this.filters.status = "ALL DATA";
  }
};
</script>

<style lang="scss">
.custom-select {
  background-color: #fff;
  border-radius: 8px;
}

.bd-jml {
  border: 1px solid #e8e8e8;
}

.req-data .table tbody tr {
  font-size: 15px;
  th {
    padding: 4px;
  }
  td {
    &:nth-child(2) {
      width: 3%;
    }
    &:nth-child(3) {
      width: 80%;
    }
    padding: 4px;
  }
}

.rm-mp {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  text-align: right;

  color: #e63b3b;
}
</style>
